import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Page404 from 'templates/Page404';
import { Page404Types } from '../@types/content/page404';

const ErrorPage: FC<Page404Types.IPropsError404Page> = () => {
  const { umbraco404Page } = useStaticQuery(graphql`
    {
      umbraco404Page(url: { eq: "/404-page/" }) {
        url
        seoMetaDescription
        seoMetaKeywords
        seoMetaTitle
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        defaultCompositions {
          ...DefaultCompositionsFragment
        }
        backBtn {
          properties {
            buttonText
            buttonLink {
              icon
              name
              published
              trashed
              url
            }
          }
        }
        title {
          value
        }
        error
      }
    }
  `);

  return umbraco404Page && <Page404 {...umbraco404Page} />;
};

export default ErrorPage;
