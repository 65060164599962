import React, { FC } from 'react';

import Button from 'common/Button';
import { Layout, Container, Col } from 'layout';

import './Page404Main.scss';
import './Page404MainExtends.scss';

import { Page404Types } from '../../@types/content/page404';

const Page404: FC<Page404Types.IPage> = ({
  seoMetaTitle,
  seoMetaDescription,
  seoMetaKeywords,
  seoExternalHreflangs,
  title,
  defaultCompositions,
  error,
  backBtn,
}) => {
  return (
    <Layout
      defaultCompositions={defaultCompositions}
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs }}
    >
      <div className="error-page">
        <Container fluid className="error-page__container">
          <Col sm="10" md="10" lg="10" xl="10">
            <h1 className="error-page__title">
              {title?.map(({ value }) => (
                <span key={value} className="error-page__title-line">
                  {value}
                </span>
              ))}
            </h1>
            {error ? <div className="error-page__error">{error}</div> : null}

            {backBtn?.[0].properties ? (
              <Button
                link={backBtn[0].properties.buttonLink[0].url}
                classes="error-page__btn"
                ariaLabel={backBtn[0].properties.buttonLink[0].name}
                btnColorVariant="white"
              >
                {backBtn[0].properties.buttonText}
              </Button>
            ) : null}
          </Col>
        </Container>
      </div>
    </Layout>
  );
};

export default Page404;
